import axios from "axios";
import { getAuth } from "./identity.service";

export const postWithAuth = (url, entity) => {
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        const headers = {
            "content-type": "application/json",
            "x-access-token": auth?.token,
        };
        axios
            .post(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message });
            });
    });
};

export const putWithOutAuth = (url, data) => {
    const headers = {
        "content-type": "application/json",
    };
    return new Promise((resolve, _reject) => {
        axios
            .put(url, data, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message });
            });
    });
};

export const putWithAuth = (url, data) => {
    const auth = getAuth();
    const headers = {
        "content-type": "application/json",
        "x-access-token": auth?.token,
    };
    return new Promise((resolve, _reject) => {
        axios
            .put(url, data, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message });
            });
    });
};

export const postWithOutAuth = (url, entity) =>
    new Promise((resolve, _reject) => {
        axios
            .post(url, entity)
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message, errorMessage: ex.response.data.message });
            });
    });

export const getWithAuth = (url, token = "") => {
    const headers = {
        "content-type": "application/json",
        "x-access-token": token,
    };
    return new Promise((resolve, reject) => {
        axios
            .get(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message });
            });
    });
};

export const getWithOutAuth = (url) => {
    return new Promise((resolve, reject) => {
        axios
            .get(url)
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message });
            });
    });
};
