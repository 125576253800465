import Container from "../atoms/Container";
import LinkButton from "../atoms/LinkButton";
import Logo from "../atoms/Logo";
import Text from "../atoms/Text";
import Image from "next/image";
import React from "react";
const Footer = ({
  logo,
  logoAlt,
  logoWidth,
  logoHeight,
  footer,
  ...property
}) => {
  const openSocial = (link) => {
    window.open(link);
  };
  return (
    <div className={`w-full shadow-top bg-white pt-4 pb-6 ${property.className}`}>
      <Container type="type0" className='flex flex-col lg:flex-row md:items-center justify-between'>
        <div className="">
          <Logo src={logo} alt={logoAlt} width={logoWidth} height={logoHeight} />
          <div className="flex flex-col lg:flex-row gap-4 md:gap-10 mt-2">
            {footer.links.map((link, key) => (
              <LinkButton  color="text-neutral-500 hover:text-primary-900" key={key} variant="body" href={link.href}>
                <a target={link?.target ? link.target : ''}>{link.title}</a>
              </LinkButton>
            ))}
          </div>
        </div>
        <div className="flex flex-col mt-10 md:mt-16 lg:mt-0 items-center lg:items-end">
          <div className="flex space-x-6 mb-4">
            {footer.socialLinks.map((social, key) => (
              <div
                className="w-12 h-12 cursor-pointer rounded-full flex justify-center items-center bg-primary-900"
                key={key}
                onClick={() => openSocial(social.link)}
              >
                <Image src={social.icon} width="24px" height="24px" />
              </div>
            ))}
          </div>
          <Text variant="body" className="text-neutral-400">
            Copyright © 2023 KashKounter
          </Text>
        </div>
      </Container>
    </div>
  );
};
export default Footer;