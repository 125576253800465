import Login from "../../organisms/Login";
import React, { useRef, useState } from "react";
import Alert from "../../atoms/Alert";

function LoginDialog({ show = false, close }) {
    if (!show) return null;

    const [alertMessage, setAlertMessage] = useState("");
    return (
        <div className="fixed top-0 left-0 bg-black bg-opacity-50 w-full h-full z-[100000] flex items-center justify-center px-6">
            <Login className="mx-auto" close={close} alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            {alertMessage && (
                <div className="absolute right-2 top-2 text-white  z-[100000]">
                    <Alert variant="successLight">{alertMessage}</Alert>
                </div>
            )}
        </div>
    );
}

export default LoginDialog;
