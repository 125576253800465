import "../styles/globals.css";
import { FlagsmithProvider } from "flagsmith/react";
import flagsmith from "flagsmith/isomorphic";
import WithAuth from "../components/Auth/WithAuth";
import { getLayoutConfig } from "@/components/Layout/LayoutConfiguration";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/grid";
import "react-calendar/dist/Calendar.css";

function MyApp({ Component, pageProps, router, flagsmithState }) {
    let layoutConfig = getLayoutConfig(router.pathname);
    const { Layout: FetchedLayout, overrides } = layoutConfig;
    return (
        <FlagsmithProvider flagsmith={flagsmith} serverState={flagsmithState}>
            <WithAuth router={router}>
                <FetchedLayout children={<Component {...pageProps} />} {...overrides} />
            </WithAuth>
        </FlagsmithProvider>
    );
}

MyApp.getInitialProps = async () => {
    await flagsmith.init({
        environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_KEY,
    });
    return { flagsmithState: flagsmith.getState() };
};

export default MyApp;
