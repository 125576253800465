import { event } from "../services/gtag.service";
import { getAuth } from "../services/identity.service";
import moment from "moment";

export const UrlParamsReplace = (urlWithPrefix, params = {}) => {
    if (params) {
        Object.keys(params).forEach((key) => (urlWithPrefix = urlWithPrefix.replace(`${key}`, params[key])));
    }
    return urlWithPrefix;
};

export const googleEventTrigger = (menuTitle) => {
    const auth = getAuth();
    const menuTriggers = {
        howitworks: "how-it-works-btn-clicked",
        contactus: "contact-us-btn-clicked",
        profile: "profile-btn-clicked",
    };
    const sanitizedMenuTitle = menuTitle.split(" ").join("").trim().toLowerCase();
    if (auth?.token && sanitizedMenuTitle in menuTriggers) {
        event(
            {
                action: menuTriggers[sanitizedMenuTitle],
            },
            {
                "user-id": auth?.id,
                email: auth?.email,
                "btn-clicked(yes/no)": "yes",
            }
        );
    }
};

export const getNumberWithTwoDecimal = (number) => {
    if (typeof number === "string") {
        return Number(number).toFixed(2);
    }
    return number.toFixed(2);
};

export const transactionStatusConverter = (tableData) => {
    const status = ["confirmed", "confirmed but delayed", "credited"];
    if (tableData?.order_created_at && !status.includes(tableData?.status)) {
        const start_date = moment(tableData?.order_created_at);
        const end_date = moment();
        const days = end_date.diff(start_date, "days");
        if (days > 100) {
            return "Rejected";
        }
    }
    const mappedObject = {
        "on hold": "Pending",
        pending: "Pending",
        created: "Pending",
        confirmed: "Confirmed",
        "confirmed but delayed": "Pending",
        declined: "Rejected",
        delivered: "Pending",
        credited: "Confirmed",
    };
    return mappedObject[tableData.status] ?? tableData.status;
};

export const getDescriptionBasedOnStatus = (tableData) => {
    const status = ["confirmed", "confirmed but delayed", "credited"];
    if (tableData?.order_created_at && !status.includes(tableData?.status)) {
        const start_date = moment(tableData.order_created_at);
        const end_date = moment();
        const days = end_date.diff(start_date, "days");
        if (days > 100) {
            return "Order Cancelled";
        }
    }

    if (!tableData.order_id && tableData.user_cashback) return "Cashback Credited";
    const statusDetails = {
        "on hold": "Order Created",
        pending: "Order Created",
        created: "Order Created",
        confirmed: "Cashback Credited",
        "confirmed but delayed": "Order Created",
        declined: "Order Cancelled",
        delivered: "Order Created",
        credited: "Cashback Credited",
    };
    return statusDetails[tableData?.status] ?? tableData.description;
};
