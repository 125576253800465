import useClickOutside from "../components/common/ClickOutside";
import Image from "next/image";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import Avatar from "../atoms/Avatar";
import Button from "../atoms/Button";
import IcomoonIcon from "../atoms/IcomoonIcon";
import Logo from "../atoms/Logo";
import MenuList from "../atoms/MenuList";
import Text from "../atoms/Text";
import FullScreenNav from "../components/common/FullScreenNav";
import { useRouter } from "next/router";
import { googleEventTrigger } from "../helper/miscellaneous";
import Container from "../atoms/Container";
import LoginDialog from "../components/common/LoginDialog";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Navbar = ({
    className = "",
    shadow = "shadow-nav",
    logo = "/images/KashKounter.svg",
    logoAlt = "MVP Rockets",
    logoWidth = "140",
    logoHeight = "40",
    isMenu,
    menuItem = [],
    buttonLabel = "Logout",
    buttonStyle = "ghost",
    navItemsSpace,
    username,
    buttonHandler,
    isLoginUser,
    avatar,
    alwaysOpen,
    logoutHandler,
    auth,
}) => {
    const [fullNav, setfullNav] = useState(false);
    const [showLoginDialog, setShowLoginDialog] = useState(false);

    const handleFulNav = () => {
        setfullNav((prevState) => true);
    };
    const handleCloseFulNav = () => {
        setfullNav((prevState) => false);
    };

    const handleNavLinks = () => {
        setfullNav((prevState) => false);
    };

    const [profile, setProfile] = useState(false);

    const handleProfile = () => {
        setProfile(!profile);
    };

    const close = () => {
        setProfile(false);
    };

    const dialogRef = useRef(null);
    const router = useRouter();

    if (!alwaysOpen) useClickOutside(dialogRef, () => close());

    const dropdownItems = [{ title: "Profile", active: true, href: "/profile" }];
    return (
        <>
            <FullScreenNav
                logo="/images/KashKounter.png"
                logoAlt="logo"
                logoHeight="50"
                logoWidth="150"
                username={username}
                handleCloseFulNav={handleCloseFulNav}
                buttonHandler={() => {
                    cookies.set("CURRENTPATH", JSON.stringify({ currentPath: window.location.pathname }), { path: "/" });
                    setfullNav(false);
                    setShowLoginDialog(true);
                }}
                fullNav={fullNav}
                avatar={avatar}
                menuItem={menuItem}
                isLoginUser={isLoginUser}
                logoutHandler={logoutHandler}
                handleNavLinks={handleNavLinks}
                className={`lg:hidden ${fullNav ? "ease-in-out duration-100" : "ease-in-out duration-100 -translate-x-[100%]"} `}
            />
              <div className={`${shadow} ${className} h-14 lg:h-20 flex items-center fixed z-[51] bg-white w-full`}>
                <Container type='type0' className="flex flex-row justify-between items-center">
                    <div className="flex space-x-3 items-start lg:hidden">
                        <div className="" onClick={handleFulNav}>
                            <IcomoonIcon icon={"hamburger"} size="30" color={"#1F1F37"} />
                        </div>
                        <Logo src={"/images/KashKounterMobile.png"} alt={"mobile-logo"} width={"30px"} height={"30px"} />
                    </div>
                    <Logo src={logo} alt={logoAlt} width={logoWidth} height={logoHeight} className="hidden lg:block" />
                    <div className={`flex items-center ${navItemsSpace}`}>
                        {isMenu ? <MenuList className="hidden lg:block" googleEventTrigger={googleEventTrigger} menuItem={menuItem} /> : ""}
                        <div className="hidden lg:block">
                            {isLoginUser ? (
                                <div className="flex items-center space-x-6">
                                    <div className="flex space-x-2 items-center cursor-pointer" onClick={() => router.push(`/user/${auth.id}/wallet`)}>
                                        <Image src={"/images/wallet.svg"} width="40px" height="40px" />
                                        <Text variant="bodySmall" className="text-neutral-800 text-sm md:text-base" fontWeight="font-medium">
                                            Wallet
                                        </Text>
                                    </div>
                                    <div ref={dialogRef} onClick={handleProfile} className="relative">
                                        {profile && (
                                            <div className="px-6 py-3 lg:space-y-4 bg-white shadow-md border border-neutral-100 rounded-lg w-fit absolute z-[62] right-0 top-[40px] hidden lg:flex lg:flex-col min-w-[160px]">
                                                <MenuList
                                                    handleNavLinks={handleNavLinks}
                                                    googleEventTrigger={googleEventTrigger}
                                                    listClass="flex-col !space-x-0 space-y-5 text-neutral-500"
                                                    menuItem={dropdownItems}
                                                    isDropMenu={true}
                                                />
                                                <div
                                                    className="cursor-pointer text-neutral-500 hover:text-primary-900 transition-all ease-in-out duration-700 text-sm md:text-base font-medium"
                                                    onClick={() => {
                                                        logoutHandler();
                                                        setProfile(false);
                                                    }}
                                                >
                                                    Logout
                                                </div>
                                            </div>
                                        )}
                                        <div className="flex space-x-2 items-center cursor-pointer">
                                           
                                            <Avatar size="medium" imgSrc={avatar ? avatar : '/images/comman-profile.svg'} />

                                            <div className="flex space-x-1 items-center">
                                                <Text variant="bodySmall" className="text-neutral-800 text-sm md:text-base " fontWeight="font-medium">
                                                    Hi, {username}
                                                </Text>
                                                <IcomoonIcon icon={"arrow-right"} className={`transition-all ${profile ? "-rotate-90" : "rotate-90"}`} size={"16"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Button
                                    label={buttonLabel}
                                    size="medium"
                                    style={buttonStyle}
                                    onClick={() => {
                                        cookies.set("CURRENTPATH", JSON.stringify({ currentPath: window.location.pathname }), { path: "/" });
                                        setShowLoginDialog(true);
                                    }}
                                    className="rounded-full"
                                />
                            )}
                        </div>
                        <div className="lg:hidden">
                            {isLoginUser ? (
                                <div className="flex space-x-5 items-center">
                                    <div
                                        onClick={() => {
                                            router.push("/categories");
                                        }}
                                        className="flex items-center justify-between"
                                    >
                                        <Image src={"/images/stores.svg"} width="30px" height="30px" />
                                    </div>
                                    <div
                                        onClick={() => {
                                            router.push(`/user/${auth.id}/wallet`);
                                        }}
                                        className="flex items-center justify-between"
                                    >
                                        <Image src={"/images/wallet.svg"} width="30px" height="30px" />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    onClick={() => {
                                        router.push("/categories");
                                    }}
                                    className="flex items-center justify-between"
                                >
                                    <Image src={"/images/stores.svg"} width="30px" height="30px" />
                                </div>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
            <LoginDialog show={showLoginDialog || (auth ? !auth.name : false)} close={() => setShowLoginDialog(false)} />
        </>
    );
};

export default Navbar;

Navbar.propTypes = {
    shadow: PropTypes.string,
    logo: PropTypes.string,
    logoAlt: PropTypes.string,
    username: PropTypes.string,
    navItemsSpace: PropTypes.string,
    menuItem: PropTypes.array,
};









