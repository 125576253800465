import React from "react";
import PropTypes from "prop-types";
const Container = ({ children, type = "type1", className, ...property }) => {
  const contentType = {
    type0: "type0",
    type1: "type1",
    type2: "type2",
    type3: "type3",
    type4: "type4",
    type5: "type5",
    type6: "type6",
    type7: "type7",
  };
  const containerStyle = {
    type0: "w-11/12 md:max-w-[1200px] mx-auto ",
    type1: "w-full md:max-w-[1158px] mx-auto px-4 md:px-6",
    type2: "w-full md:max-w-184.252",
    type3: "w-full md:max-w-102.752",
    type4: "w-full md:max-w-[760px] mx-auto px-4 md:px-6",
    type5: "w-full md:max-w-211.5 mx-auto",
    type6: "w-full md:max-w-48.5",
    type7: "w-full md:max-w-157",
    type8: "w-full md:max-w-130",
    type9: " w-full md:max-w-211.5 mx-auto ",
  };
  return (
    <div
      type={contentType[type]}
      className={` ${containerStyle[type]} ${className || ""}`}
      {...property}
    >
      {children}
    </div>
  );
};
export default Container;
Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};







