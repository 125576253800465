const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

const UrlParamsReplace = (url, params = {}) => {
    let urlWithPrefix = `${ApiUrl}${url}`;
    if (params) {
        Object.keys(params).forEach((key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key])));
    }
    return urlWithPrefix;
};

export const GET_USERS_URL = () => UrlParamsReplace("/users");

//categories
export const GET_CATEGORIES_URL = (name) => UrlParamsReplace("/categories?name=:name", { name });

//merchants
export const GET_MERCHANT_DETAILS_URL = (id) => UrlParamsReplace("/activeMerchant/:id", { id });
export const GET_ALL_MERCHANTS = () => UrlParamsReplace("/allMerchants");
export const GET_MERCHANT_BANNERS_URL = (id) => UrlParamsReplace("/merchantBanner/:id", { id });

//coupons
export const GET_COUPON_URL = (merchantId, couponId) => UrlParamsReplace("/merchant/:merchantId/coupon/:couponId", { merchantId, couponId });

//users
export const GET_USER_URL = (id) => UrlParamsReplace("/user/:id ", { id });
export const USER_GOOGLE_LOGIN_URL = () => UrlParamsReplace("/auth/google?state=user");
export const USER_FACEBOOK_LOGIN_URL = () => UrlParamsReplace("/auth/facebook?state=user");
export const VERIFY_TOKEN_URL = () => UrlParamsReplace("/token/verify");
export const UPDATE_USER_URL = (id) => UrlParamsReplace("/user/:id", { id });
export const LOGIN_USER = UrlParamsReplace("/authentication/verify-otp");
export const GET_LOGIN_OTP = UrlParamsReplace("/authentication/otp");
export const GET_RESENT_OTP = UrlParamsReplace("/authentication/resend-otp");
export const UPDATE_NEW_USER_URL = (id) => UrlParamsReplace("/newUser/:id", { id });

//upload image
export const GET_UPLOAD_PRE_SIGNED_URL = UrlParamsReplace("/aws/get-upload-pre-signed-url");

//wallet
export const GET_WALLET_DETAILS_URL = (id) => UrlParamsReplace("/users/:id/wallet", { id });

//wallet-histories
export const GET_WALLET_HISTORY_DETAILS_URL = (id, offset, limit) => UrlParamsReplace("/users/:id/walletHistories?offset=:offset&limit=:limit", { id, offset, limit });

//banner image
export const GET_BANNER_IMAGES_URL = (offset, limit, search, status, name, availableForAll, order, startDate, endDate, bannerType) => {
    return UrlParamsReplace(
        "/banner-images?offset=:offset&limit=:limit&search=:search&status=:status&availableForAll=:availableForAll&name=:name&order=:order&startDate=:startDate&endDate=:endDate&bannerType=:bannerType",
        {
            offset,
            limit,
            search,
            status,
            name,
            availableForAll,
            order,
            startDate,
            endDate,
            bannerType,
        }
    );
};

export const GET_ACTIVE_BANNER_URL = () => UrlParamsReplace("/active-banner-image");
export const GET_BANNER_IMAGE_URL = (id) => UrlParamsReplace("/banner-image/:id", { id });

//setting
export const GET_WALLET_SETTING_URL = () => UrlParamsReplace("/walletSetting");

export const CONTACT_US_URL = () => UrlParamsReplace("/contact-us");
// transaction url
export const CREATE_MISSING_TRANSACTION_URL = () => UrlParamsReplace("/missingTransactionReport");

//clickActivities
export const GET_ALL_CLICK_ACTIVITIES = (offset, limit, search, userId, name, order, token) =>
    UrlParamsReplace("/allClickActivities?offset=:offset&limit=:limit&search=:search&userId=:userId&name=:name&order=:order", {
        offset,
        limit,
        search,
        userId,
        name,
        order,
        token,
    });

export const CREATE_CLICK_ACTIVITIES_URL = () => UrlParamsReplace("/clickActivity");
//referee
export const GET_REFEREE_URL = (userId) => UrlParamsReplace("/referee?userId=:userId", { userId });
export const CREATE_REFEREE_URL = () => UrlParamsReplace("/referee");

//referral
export const GET_REFERRAL_CODE = (code) => UrlParamsReplace("/referralCode?code=:code", { code });
export const UPDATE_REFERRAL_OPTIN = (id) => UrlParamsReplace("/user/:id/referral-optin", { id });
