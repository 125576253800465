import PropTypes from "prop-types";

const Text = ({
  variant = "body",
  fontSize = "",
  fontWeight = "",
  children,
  className,
  onClick,
}) => {
  const TextType = {
    body: "body",
    bodyLarge: "bodyLarge",
    bodySmall: "bodySmall",
    caption: "caption",
    large: 'large',
    extraLarge: "extraLarge",
  };

  const TextStyle = {
    body: "text-base",
    bodyLarge: "text-base md:text-lg",
    bodySmall: "text-sm",
    caption: "text-xs",
    large: 'text-xl md:text-2xl',
    extraLarge: "text-2xl md:text-3xl",
  };
  return (
    <p
      variant={TextType[variant]}
      className={`${TextStyle[variant]} ${fontSize} ${fontWeight} ${className}`}
      onClick={onClick}
    >
      {children}
    </p>
  );
};

export default Text;

Text.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  className: PropTypes.string,
};
