import MenuList from "../../atoms/MenuList";
import PropTypes from "prop-types";
import { useEffect } from "react";
import Avatar from "../../atoms/Avatar";
import Button from "../../atoms/Button";
import IcomoonIcon from "../../atoms/IcomoonIcon";
import Logo from "../../atoms/Logo";
import Text from "../../atoms/Text";
import { useRouter } from "next/router";
import { googleEventTrigger } from "../../helper/miscellaneous";

const FullScreenNav = ({
    logo,
    handleNavLinks,
    logoAlt,
    logoWidth,
    logoHeight,
    buttonHandler,
    username,
    handleCloseFulNav,
    fullNav,
    avatar,
    menuItem,
    isLoginUser,
    ...property
}) => {
    useEffect(() => {
        if (fullNav) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "visible";
        }
    }, [fullNav]);
    const router = useRouter();

    const handleProfile = () => {
        handleCloseFulNav();
        router.push("/profile");
    };
    return (
        <div className={`fixed  top-0 z-[55] h-screen flex bg-black bg-opacity-90 w-full ${property.className}`}>
            <div className="w-[70%] bg-white h-full">
                <Logo src={logo} alt={logoAlt} width={logoWidth} height={logoHeight} className="px-4 py-2" />
                <div className="px-4 py-3 bg-secondary-900 text-neutral-900">
                    {isLoginUser ? (
                        <div className="flex space-x-4 items-center">
                            <Avatar size="medium" imgSrc={avatar} />
                            <Text variant="bodySmall" fontWeight="font-semibold">
                                Hi, {username}
                            </Text>
                        </div>
                    ) : (
                        <Button label={"Login"} size="small" style={"primary"} className="rounded-full w-fit" onClick={buttonHandler} />
                    )}
                </div>
                <div className="px-4 py-6 flex flex-col space-y-5">
                    {isLoginUser && (
                        <Text variant="bodySmall" onClick={handleProfile}>
                            Profile
                        </Text>
                    )}
                    <MenuList className="" menuItem={menuItem} handleNavLinks={handleNavLinks} googleEventTrigger={googleEventTrigger} listClass="flex-col !space-x-0 space-y-5" />
                    {isLoginUser && <Button label={"Sign Out"} size="small" style={"ghost"} className="rounded-full w-fit" onClick={property.logoutHandler} />}
                </div>
            </div>
            <div className="px-3 py-5">
                <IcomoonIcon icon={"close-b"} size="20" color={"white"} className="cursor-pointer" onClick={handleCloseFulNav} />
            </div>
        </div>
    );
};

export default FullScreenNav;

FullScreenNav.propTypes = {
    className: PropTypes.string,
};
