import Text from "../atoms/Text";
import Link from "next/link";
import Image from "next/image";
import { event } from "../services/gtag.service";
import Heading from "../atoms/Heading";
import { USER_GOOGLE_LOGIN_URL, USER_FACEBOOK_LOGIN_URL } from "../services/url.service";
import InputBox from "../atoms/InputBox";
import Button from "../atoms/Button";
import LoginOtpForm from "../molecules/LoginOtpForm";
import LoginUserDetails from "../molecules/LoginUserDetails";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupValidator } from "../components/Utilities/yup-validator";
import { useState, useEffect } from "react";
import { loginUser, getUserOTPLogin, getResentOTP, updateNewUser } from "../services/user.service";
import { setAuth, getAuth } from "../services/identity.service";
import { useRouter } from "next/router";

const validateEmail = (email) => {
    let validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email) {
        return email.match(validRegex) ? true : false;
    } else {
        return false;
    }
};

const Login = ({ logo, logoAlt, logoHeight, logoWidth, width = "max-w-[490px] w-full", className, close, alertMessage, setAlertMessage, ...property }) => {
    const {
        formState: { errors },
        setValue,
        getValues,
        register,
        watch,
    } = useForm({
        mode: "onChange",
        resolver: yupValidator(
            yup.object().shape({
                email: yup
                    .string()
                    .email()
                    .test("email", "Email ID must be valid!", (email) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
                    .required("Email is mandatory!"),
                otp: yup.string().required("OTP is mandatory!"),
                firstName: yup.string().required("First Name is mandatory!"),
                lastName: yup.string().required("Last Name is mandatory!"),
                mobileNumber: yup
                    .string()
                    .required("Mobile Number is required!")
                    .length(10, "Invalid mobile number!")
                    .matches(/^[1-9]\d{9}$/, "Invalid mobile number!"),
            })
        ),
    });
    const Router = useRouter();

    const auth = getAuth();
    const [otpInput, setOtpInput] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [timerValue, setTimerValue] = useState(0);
    const [showResend, setShowResend] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [otpValue, setOtpValue] = useState("");
    const [disableVerify, setDisableVerify] = useState(true);
    const [otpDisplayValue, setOtpDisplayValue] = useState("");
    const [otpId, setOtpId] = useState("");
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    let timerId;
    const loginBtns = [
        {
            image: "/images/google-logo.svg",
            label: "Continue with Google",
            type: "Google",
            href: USER_GOOGLE_LOGIN_URL(),
        },
        // {
        //   image: "/images/facebook-logo.svg",
        //   label: "Sign in with Facebook",
        //   type: "Facebook",
        //   href: USER_FACEBOOK_LOGIN_URL(),
        // },
    ];

    const onSubmit = async ({ email, otp, otpId }) => {
        const { entity, status, errorMessage } = await loginUser(email, otp, otpId);

        if (status && entity) {
            if (entity?.newUser) {
                setIsEmailVerified((prev) => true);
            } else {
                Router.reload();
            }
            setAuth(entity);
        } else {
            setShowAlert(true);
            setErrorMessage({ otp: errorMessage || "Incorrect OTP entered" });
            setTimeout(() => {
                setShowAlert(false);
                setErrorMessage(false);
            }, 5000);
        }
    };
    useEffect(() => {
        if (isEmailVerified) {
            setAlertMessage((prev) => "Email Verified Successfully");
        }
    }, [isEmailVerified]);

    useEffect(() => {
        if (isOtpSent) {
            setAlertMessage((prev) => "Otp Sent Successfully");
        }
    }, [isOtpSent]);

    useEffect(() => {
        if (alertMessage) {
            setTimeout(() => {
                setAlertMessage((prev) => "");
            }, 3000);
        }
    }, [alertMessage]);

    const sendOtp = async (email, resend = false, otpId) => {
        const isEmailValid = validateEmail(email);

        if (email === "") {
            setShowAlert(true);
            setIsLoading(false);
            setErrorMessage({ email: "Email address field cannot be blank" });
            setTimeout(() => {
                setShowAlert(false);
                setErrorMessage(false);
            }, 3000);
            return;
        }

        if (!isEmailValid) {
            setShowAlert(true);
            setIsLoading(false);
            setErrorMessage({ email: "Invalid email address" });
            setTimeout(() => {
                setShowAlert(false);
                setErrorMessage(false);
            }, 3000);
            return;
        }
        const { status, entity } = !resend ? await getUserOTPLogin(email) : await getResentOTP(email, otpId);
        if (status) {
            setOtpId((prev) => entity?.id);
            setOtpInput(true);
            setIsOtpSent(true);
            resend && setAlertMessage((prev) => "Otp Sent Successfully");
            setTimerValue(60);
            setShowResend(false);
        } else {
            setShowAlert(true);
            setIsLoading(false);
            setErrorMessage({ error: "Oops something went wrong" });
        }
    };

    const updateUser = async ({ name, mobileNumber }) => {
        const auth = getAuth();
        const { status, entity } = await updateNewUser(auth.id, {
            name,
            mobileNumber,
        });
        if (status) {
            setAuth({ ...auth, ...entity });
            setTimeout(() => Router.replace("/"), 500);
        } else {
            setShowAlert(true);
            setErrorMessage({ update: "update Failed" });
            setTimeout(() => {
                setShowAlert(false);
                setErrorMessage(false);
            }, 3000);
        }
    };

    const openLogin = (link) => {
        window.open(link, "_self");
    };

    useEffect(() => {
        timerId = setTimeout(() => {
            if (timerValue == 0) {
                setShowResend(true);
                return clearTimeout(timerId);
            }

            setTimerValue(timerValue - 1);
        }, 1000);
    }, [timerValue]);

    return (
        <div className={`${width} relative bg-white shadow-medium text-center rounded-xl py-14  md:py-16 px-4 md:px-16 ${className}`}>
            {!isOtpSent && !isEmailVerified && !auth && (
                <>
                    <Heading type="h4" className="text-2xl font-semibold ">
                        Enter KashKounter
                    </Heading>
                    <div className="w-full mt-6">
                        <InputBox
                            inputStyle="Large"
                            inputSize="Large"
                            labelClass="bg-white"
                            isLabel
                            labelText=""
                            labelFor="email"
                            name="email"
                            placeholder="Enter your Email"
                            variant="Default"
                            type="email"
                            id="email"
                            dbName="email"
                            required={true}
                            setValue={setValue}
                            register={register}
                            disabled={isOtpSent}
                            getValues={getValues}
                            errorMessage={errors?.email?.message}
                            isError={errors?.email}
                        />

                        <Button
                            className="text-center rounded-full w-full my-6"
                            style="primary"
                            label="Send OTP to Email"
                            size="medium"
                            disabled={!validateEmail(watch("email"))}
                            onClick={() => sendOtp(getValues("email"))}
                        />
                    </div>

                    <div className="flex items-center">
                        <hr className="flex-grow text-gray-400" />
                        <span className="text-gray-400 px-3">OR </span>
                        <hr className="flex-grow text-gray-400" />
                    </div>

                    <div className="flex flex-col gap-2 md:gap-4 mb-4 mt-6 ">
                        {loginBtns.map((btn, idx) => (
                            <div
                                key={idx}
                                onClick={() => {
                                    event(
                                        {
                                            action: "login-attempt",
                                            category: "Button",
                                            label: "Log In",
                                        },
                                        { "btn-clicked": "yes", "type(google/facebook)": btn.type }
                                    );

                                    return openLogin(btn.href);
                                }}
                                className="relative rounded-full flex justify-center items-center text-sm text-neutral-800 md:text-base space-x-3 py-3 px-10 md:px-14 cursor-pointer w-full bg-neutral-50 active:bg-neutral-300  hover:bg-neutral-100 border border-neutral-300"
                            >
                                <div className="absolute left-6 top-1/2 transform -translate-y-1/2 flex items-center justify-center">
                                    <Image src={btn.image} width="24px" height="24px" className="flex-shrink-0" />
                                </div>
                                <Text variant="" className="w-40 md:w-48 text-center text-sm md:text-base">
                                    {btn.label}
                                </Text>
                            </div>
                        ))}
                    </div>

                    <Text className="text-xs md:text-sm">
                        By joining, I agree to the
                        <Link href="/termsandconditions">
                            <a rel="noopener noreferrer" className="text-primary-900 inline-block mx-1">
                                Terms & Conditions
                            </a>
                        </Link>
                        and
                        <Link href="/privacyPolicy">
                            <a rel="noopener noreferrer" className="text-primary-900 inline-block mx-1">
                                Privacy Policy
                            </a>
                        </Link>
                    </Text>
                </>
            )}
            {isOtpSent && !isEmailVerified && !auth && (
                <LoginOtpForm
                    email={watch("email")}
                    setValue={setValue}
                    register={register}
                    getValues={getValues}
                    errorMessage={errorMessage}
                    otpValue={otpValue}
                    setOtpValue={setOtpValue}
                    disableVerify={disableVerify}
                    setDisableVerify={setDisableVerify}
                    onSubmit={onSubmit}
                    otpId={otpId}
                    showResend={showResend}
                    setShowResend={setShowResend}
                    timerValue={timerValue}
                    sendOtp={sendOtp}
                    watch={watch}
                />
            )}
            {(isEmailVerified || (auth ? !auth?.name : false)) && (
                <LoginUserDetails updateUser={updateUser} register={register} getValues={getValues} errorMessage={errorMessage} errors={errors} />
            )}
            {!isEmailVerified && !auth && (
                <button
                    className="absolute right-3 top-3 mb-6 md:mb-0 font-semibold rounded-full bg-primary-900 py-1 px-2 text-white text-sm"
                    onClick={() => {
                        close();
                    }}
                >
                    X
                </button>
            )}
        </div>
    );
};

export default Login;
