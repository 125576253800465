import React from "react";
import PropTypes from "prop-types";
import Navbar from "../../molecules/Navbar";
import { useRouter } from "next/router";
import { getAuth, removeAuth } from "../../services/identity.service";
import { useState, useEffect } from "react";
import Footer from "../../organisms/Footer";
import { event } from "../../services/gtag.service";
import Cookies from "universal-cookie";
import Container from "atoms/Container";
import Loader from "../../atoms/Loaders";
const cookies = new Cookies();

const UserLayout = ({ children }) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);
  const auth = getAuth();
  const logoutHandler = () => {
    event(
      {
        action: "sign-out-btn-clicked",
      },
      {
        "user-id": auth?.id,
        email: auth?.email,
        "page-name": window.location.pathname,
        "btn-clicked(yes/no)": "yes",
      }
    );
    removeAuth();
    cookies.remove("CURRENTPATH", { path: "/" });
    router.reload("/");
  };
  useEffect(() => {
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    router.events.on("routeChangeStart", start);
    router.events.on("routeChangeComplete", end);
    router.events.on("routeChangeError", end);
    return () => {
      router.events.off("routeChangeStart", start);
      router.events.off("routeChangeComplete", end);
      router.events.off("routeChangeError", end);
    };
  }, []);
  const [navLink, setnavLink] = useState([
    {
      title: "Brands",
      active: true,
      href: "/categories",
    },
    {
      href: "/about",
      title: "About Us",
    },
    {
      title: "How it Works",
      active: true,
      href: "/#howItWorks",
    },
    {
      title: "Contact Us",
      active: false,
      href: "/contactUs",
    },
  ]);
  const menuItems = [
    { title: "How it Works", active: true },
    { title: "Contact Us", active: false },
  ];
  const footer = {
    logo: "https://www.google.com/",
    links: [
      {
        href: "/categories",
        title: "Brands",
      },
      {
        href: "/about",
        title: "About Us",
      },
      {
        href: "/#howItWorks",
        title: "How it Works",
      },
      {
        href: "https://www.kashkounter.com/blog",
        title: "Blog",
        target: '_blank'
      },
      {
        href: "/termsandconditions",
        title: "Terms & Conditions",
      },
      {
        href: "/contactUs",
        title: "Contact Us",
      },
    ],
    socialLinks: [
      {
        link: "https://www.facebook.com/KashKounterApp/",
        icon: "/images/facebook.svg",
      },
      {
        link: "https://www.instagram.com/KashKounterApp/",
        icon: "/images/instagram.svg",
      },
      {
        link: "https://www.youtube.com/@KashKounter",
        icon: "/images/youtube.svg",
      },
    ],
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {domLoaded && (
            <Navbar
              shadow="drop-shadow"
              username={auth?.name}
              navItemsSpace="space-x-10"
              isMenu={true}
              logoHeight="55"
              logoWidth="240"
              menuItem={navLink}
              buttonStyle="primary"
              buttonLabel="Login"
              auth={auth}
              isLoginUser={auth?.token}
              logoutHandler={logoutHandler}
              avatar={auth?.profilePic}
              menuItems={menuItems}
            />
          )}
          <div className="pt-[56px] lg:pt-[80px]">{children}</div>

          <Footer
            logo={"/images/KashKounter.svg"}
            logoAlt="KashKounterLogo"
            logoHeight="60"
            logoWidth="220"
            footer={footer}
          />
        </div>
      )}
    </div>
  );
};
UserLayout.propTypes = {
  children: PropTypes.node,
};
export default UserLayout;
