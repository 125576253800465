import EmptyLayout from "./EmptyLayout";
import UserLayout from "./UserLayout";

const LayoutConfig = [
    {
        path: "/login",
        Layout: EmptyLayout,
    },
    {
        path: "/success",
        Layout: EmptyLayout,
    },
    {
        path: "/profile",
        Layout: UserLayout,
    },
    {
        path: "/intermediate",
        Layout: EmptyLayout,
    },
    {
        path: "/",
        Layout: UserLayout,
    },
];

const getLayoutConfig = (path) => {
    let config = LayoutConfig.find((configItem) => path.includes(configItem.path));
    if (config) return config;
    else return { Layout: EmptyLayout };
};

export { getLayoutConfig };
