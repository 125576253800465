import Heading from "../atoms/Heading";
import InputBox from "../atoms/InputBox";
import Button from "../atoms/Button";

const LoginUserDetails = ({ errors, register, getValues, updateUser, isValid, ...property }) => (
    <div className="w-full ">
        <Heading type="h4" className="text-center font-semibold">
            Enter your details
        </Heading>
        <InputBox
            labelClass="bg-white mb-4"
            isLabel
            labelText=""
            labelFor="First Name"
            name="firstName"
            placeholder="Enter your first name"
            variant="Large"
            type="text"
            id="firstName"
            dbName="firstName"
            required={true}
            register={register}
            getValues={getValues}
            errorMessage={errors?.firstName?.message}
            isError={errors?.firstName}
            errorClass='block text-left'
        />

        <InputBox
            labelClass="bg-white mb-4"
            isLabel
            labelText=""
            labelFor="Last Name"
            name="lastName"
            placeholder="Enter your last name"
            variant="Large"
            type="text"
            id="lastName"
            dbName="lastName"
            register={register}
            getValues={getValues}
            errorMessage={errors?.lastName?.message}
            isError={errors?.lastName}
            errorClass='block text-left'
        />

        <InputBox
            labelClass="bg-white mb-4"
            isLabel
            labelText=""
            labelFor="Contact Number"
            name="mobileNumber"
            placeholder="Enter your mobile number"
            variant="Large"
            type="text"
            id="mobileNumber"
            dbName="mobileNumber"
            register={register}
            getValues={getValues}
            errorMessage={errors?.mobileNumber?.message}
            isError={errors?.mobileNumber}
            errorClass='block text-left'
        />

        <Button
            className="flex flex-row items-center justify-center text-center w-full border rounded-full mt-5 outline-none py-3 bg-primary-900  font-semibold border-none text-white text-sm shadow-sm"
            onClick={() => {
                const name = `${getValues("firstName")} ${getValues("lastName")}`;

                updateUser({ name, mobileNumber: getValues("mobileNumber") });
            }}
            disabled={Object.keys(errors).length !== 0 || (!getValues("firstName") && !getValues("lastName") && !getValues("mobileNumber"))}
            label="Submit"
        />
    </div>
);

LoginUserDetails.propTypes = {};

export default LoginUserDetails;
