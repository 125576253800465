import NextImage from "next/image";
import PropTypes from "prop-types";

const Logo = ({ ...property }) => (
  <div className={`cursor-pointer w-fit select-none ${property.className}`}>
    <a href="/" className="flex items-center justify-center">
      <NextImage
        onClick={property.onClick}
        src={property.src}
        width={property.width}
        height={property.height}
        alt={`${property.alt}`}
        objectFit='contain'
        objectPosition='left'
      />
    </a>
  </div>
);

export default Logo;

Logo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  alt: PropTypes.string,
};
