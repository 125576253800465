
import * as HttpService from "./http.service";
import {
    GET_USER_URL,
    UPDATE_USER_URL,
    CONTACT_US_URL,
    CREATE_REFEREE_URL,
    GET_REFEREE_URL,
    GET_REFERRAL_CODE,
    UPDATE_REFERRAL_OPTIN,
    LOGIN_USER,
    GET_LOGIN_OTP,
    GET_RESENT_OTP,
    UPDATE_NEW_USER_URL,
} from "./url.service";

export const getUser = (id, token) => {
    return HttpService.getWithAuth(GET_USER_URL(id), token);
};

export const updateUser = (id, data) => {
    return HttpService.putWithAuth(UPDATE_USER_URL(id), data);
};
export const addContactUsInfo = (data) => {
    return HttpService.postWithAuth(CONTACT_US_URL(), data);
};

export const createReferee = (details) => {
    return HttpService.postWithAuth(CREATE_REFEREE_URL(), details);
};

export const getReferee = (userId, token) => {
    return HttpService.getWithAuth(GET_REFEREE_URL(userId), token);
};
export const getReferralCode = (code, token) => {
    return HttpService.getWithAuth(GET_REFERRAL_CODE(code), token);
};
export const updateReferralOptIn = (id, data) => HttpService.putWithAuth(UPDATE_REFERRAL_OPTIN(id), data);

export const loginUser = (email, otp, id) => {
    return HttpService.postWithOutAuth(LOGIN_USER, { email, otp, id });
};

export const getUserOTPLogin = (email) => {
    return HttpService.postWithOutAuth(GET_LOGIN_OTP, { email });
};

export const getResentOTP = (email, id) => {
    return HttpService.postWithOutAuth(GET_RESENT_OTP, { email, id });
};
export const updateNewUser = (id, data) => {
    return HttpService.putWithAuth(UPDATE_NEW_USER_URL(id), data);
};
