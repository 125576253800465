import Text from "../atoms/Text";
import Button from "../atoms/Button";
import OTPInput from "react-otp-input";

const LoginOtpForm = ({ email, setValue, register, getValues, errorMessage, onSubmit, otpValue, otpId, showResend, timerValue, sendOtp, watch, ...property }) => {
    return (
        <div className="mx-auto flex w-full flex-col">
            <div className="flex flex-col items-center justify-center text-center ">
                <div className=" mb-8">
                    <Text TextType="extraLarge" className="font-semibold text-5xl">
                        Enter the OTP
                    </Text>
                </div>
                <div className="text-sm font-medium text-neutral-500">
                    <Text>Enter the 4 digit verification code sent to</Text>
                    <Text className={`text-primary-900 mt-2 mb-6`}>{email}</Text>
                </div>
            </div>

            <div>
                <div className="">
                    <div className="flex flex-row items-center justify-center mx-auto w-full gap-5">
                        <OTPInput
                            value={watch("otp")}
                            name="otp"
                            numInputs={4}
                            inputType="tel"
                            containerStyle={{ marginBottom: "20px" }}
                            inputStyle={{
                                width: "40px",
                                height: "40px",
                                marginRight: "10px",
                                border: "1px solid black",
                            }}
                            errorStyle={{ borderColor: "red" }}
                            renderInput={(props) => <input type="number" {...props} />}
                            onChange={(o) => setValue("otp", o)}
                        />
                    </div>
                    <div>{errorMessage?.otp && <div className={`text-error-100 pl-0.5 text-sm pt-2 font-normal leading-[21px] `}>{errorMessage?.otp}</div>}</div>



                    <div className="w-full  mb-6 text-center">
                        {!showResend ? (
                             <div className="flex flex-col justify-center">
                                <span className="w-full text-center text-sm mt-2 text-neutral-500 font-normal">00:{timerValue} s</span>
                                <span className="w-full text-center mt-3 font-semibold text-primary-900">Resend OTP in</span>
                            </div>
                          
                        ) : (
                            <a
                                className="font-semibold text-primary-900 mb-6 cursor-pointer"
                                target="_blank"
                                onClick={() => {
                                    sendOtp(email, true, otpId);
                                }}
                            >
                                Resend Code
                                
                            </a>
                        )}
                    </div>
                    <div>
                        <Button
                            className="text-center w-full border rounded-full outline-none py-3 bg-primary-900  font-semibold border-none text-white text-sm shadow-sm"
                            onClick={() => onSubmit({ email: email, otp: getValues("otp"), otpId })}
                            label="Submit"
                            disabled={getValues("otp")?.length !== 4}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

LoginOtpForm.propTypes = {};

export default LoginOtpForm;
