import React from "react";
import PropTypes from "prop-types";
import Text from "./Text";
import { useRouter } from "next/router";
import Link from "next/link";
import { event } from "../services/gtag.service";

const MenuList = ({ menuItem = [], handleNavLinks, googleEventTrigger = () => {}, listClass, isDropMenu = false, ...properties }) => {
    const router = useRouter();
    return (
        <nav>
            <ul className={`flex space-x-6 ${listClass}`}>
                {menuItem.map((menu, index) => (
                    <Link key={index} href={menu.href} scroll={false}>
                        <li
                            key={index}
                            onClick={(e) => {
                                googleEventTrigger(e.target.innerHTML);
                                return handleNavLinks;
                            }}
                        >
                            <Text
                                variant="bodySmall"
                                className={`text-sm cursor-pointer hover:text-primary-900 transition-all ease-in-out duration-700 ${isDropMenu ? 'font-medium md:text-base' : 'font-normal md:text-base'} ${menu.isActive && "text-primary-900"} ${
                                    properties.className
                                }`}
                            >
                                {menu.title}
                            </Text>
                        </li>
                    </Link>
                ))}
            </ul>
        </nav>
    );
};

export default MenuList;

MenuList.propTypes = {
    className: PropTypes.string,
    listClass: PropTypes.string,
    menuItem: PropTypes.array,
};
