export const isProtected = (route) => {
    const protectedRoutes = [];

    return protectedRoutes.includes(route);
};
export const isPublic = (route) => {
    const unprotectedRoutes = [
        "/storybook",
        "/storybook/index.html",
        "/login",
        "/",
        "/success",
        "/categories",
        "/termsandconditions",
        "/contactUs",
        "/privacyPolicy",
        "/dataDeletionPolicy",
        "/about",
        ...(route.includes("/merchants") ? [route] : []),
    ];
    return unprotectedRoutes.includes(route);
};
